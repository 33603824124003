.card {
    background-color: white;
    border: solid 5px var(--yellow);
    font-weight: 700;
    color:black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height:100px;
    border-radius: 50%;
    color: var(--charcoal);
    &.active {
        color: var(--orange);
        background-color: var(--tangerine);
        &>* {
            color:white;
        }
    }
    z-index: 10;
}
.stepBarTrack {
    width: 100%;
    height: 5px;
    background-color: #D9D9D9;
    position: absolute;
    .stepBar {
        background-color: var(--yellow);
        height: inherit;
    }
    z-index: 0;
}
@media (max-width: 768px) {
    .card {
        width: 75px;
        height: 75px;
    }
}