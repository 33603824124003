.root {
    width: 100%;
    height: 10px;
    .tracActive {
        border: solid 2px white;
    }
}
.markPoint {
    font-size: 16px;
    line-height: 20px;
}
.thumb {
    cursor: pointer;
    background-color: #e16605;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    outline: none;
    position: relative;
    top: -9px;
    .value {
        width: max-content;
        position: absolute;
        top: -30px;
        left: -50px;
    }
}

.track {
    // width: 100%;
    &:nth-child(1){
        // background-color: green;    
    }
    height: 10px;
    // border-radius: 10px;
    background-color: lightgray;
}