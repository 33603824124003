.card {
	width: 100%;
    cursor: pointer;
    &:hover {
            -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,.25);
            -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,.25);
        box-shadow: 0px 4px 4px 0px rgba(0,0,0,.25);
        border: 5px solid var(--teal);
    }
    text-align: left;
	border: 5px solid var(--yellow);
	border-radius: 8px;
	background-color: var(--white);
	transition: all 0.3s ease;
		-webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,.10);
		-moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,.10);
	box-shadow: 0px 4px 4px 0px rgba(0,0,0,.10);
}