.root {
    background-color: lightgray;
    height: 16px;
    border-radius: 8px;
    .process {
        position: relative;
        background-color: green;
        height: inherit;
        border-radius: inherit; 
    }
}