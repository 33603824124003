.invalideFormControl {
    border-color: var(--bs-form-invalid-color);
    box-shadow: 0 0 0 0.25rem var(--bs-form-invalid-color);
}

.errorHelperText {
    color:var(--bs-form-invalid-color);
}

.requiredLabel {
    &::after {
        content: " *";
        color:var(--bs-form-invalid-color);
    }
}